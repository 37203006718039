import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, Subscription, tap } from 'rxjs';
import { BusinessEmailDetails } from '../../contact-forms.model';
import { ContactFormsService } from '../../services/contact-forms.service';

@Component({
  selector: 'app-business-contact',
  templateUrl: './business-contact.component.html',
  styleUrls: ['./business-contact.component.scss'],
})
export class BusinessContactComponent implements OnInit, OnDestroy {
  formEventSubject: Subject<boolean> = new Subject<boolean>();

  private eventsSubscription!: Subscription;
  constructor(private service: ContactFormsService) {}

  ngOnInit(): void {
    this.eventsSubscription = this.service.formStored.subscribe((eventType) => {
      this.formEventSubject.next(eventType);
    });
  }

  submitContactForm(formData: BusinessEmailDetails): void {
    this.service.sendBusinessMail(formData);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.eventsSubscription.unsubscribe();
  }
}
