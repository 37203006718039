import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BusinessEmailDetails } from '../contact-forms.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable()
export class ContactFormsService {
  formStored: EventEmitter<boolean> = new EventEmitter();
  resetForm: EventEmitter<boolean> = new EventEmitter();
  constructor(
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  sendBusinessMail(emailContent: BusinessEmailDetails) {
    this.recaptchaV3Service
      .execute('BUSINESSCONTACTFORM')
      .subscribe((recaptchaResponse) => {
        this.http
          .post(environment.api_url, {
            recaptchaToken: recaptchaResponse,
            to: emailContent.email,
            from: 'mailer@paxiplatform.com',
            subject: '[PAXI] Business Contact',
            html: this.formatBusinessContactForm(emailContent),
          })
          .subscribe({
            next: () => this.formStored.emit(true),
            error: () => this.formStored.emit(false),
            complete: () => console.log('done'),
          });
      });
  }

  formatBusinessContactForm(emailContent: BusinessEmailDetails): string {
    return `
      <ul>
        <li>
          <p>Full Name</p>
          <p>${emailContent.fullName}</p>
        </li>
        <li>
          <p>Phone Number</p>
          <p>${emailContent.phoneNumber}</p>
        </li>
        <li>
          <p>Company Name</p>
          <p>${emailContent.companyName}</p>
        </li>
        <li>
          <p>Email</p>
          <p>${emailContent.email}</p>
        </li>
        <li>
          <p>Message</p>
          <p>${emailContent.message}</p>
        </li>
      </ul>
    `;
  }
}
