<app-form-submit-state
  *ngIf="formSubmitted"
  [messageSuccess]="messageSuccess"
></app-form-submit-state>

<form
  (ngSubmit)="submit()"
  [formGroup]="businessContactForm"
  #myform="ngForm"
  class="form-container"
  *ngIf="!formSubmitted"
>
  <div class="form-row">
    <div class="form-input-container">
      <label aria-label class="form-label">Full Name *</label>
      <mat-form-field
        [floatLabel]="'always'"
        class="form-field"
        appearance="outline"
        [color]="'accent'"
      >
        <input
          formControlName="fullName"
          class="form-input"
          matInput
          placeholder="e.g. Jane Doe"
        />
      </mat-form-field>
      <mat-error
        *ngIf="
          businessContactForm.get('fullName')?.hasError('required') &&
          myform.submitted
        "
        >Full Name Is A Required Field</mat-error
      >
    </div>
    <div class="form-input-container">
      <label aria-label class="form-label">Phone Number *</label>
      <mat-form-field
        [floatLabel]="'always'"
        class="form-field"
        appearance="outline"
        [color]="'accent'"
      >
        <input
          formControlName="phoneNumber"
          class="form-input"
          matInput
          placeholder="e.g. 0834098041"
          (focus)="onFocus()"
        />
      </mat-form-field>
      <mat-error
        *ngIf="
          businessContactForm.get('phoneNumber')?.hasError('required') &&
          myform.submitted
        "
        >Phone Number Is A Required Field</mat-error
      >
      <mat-hint
        class="hint"
        *ngIf="
          businessContactForm.get('phoneNumber')?.dirty
            ? businessContactForm.get('phoneNumber')?.invalid
            : null
        "
        >Example +27163742632</mat-hint
      >
    </div>
  </div>

  <div class="form-row">
    <div class="form-input-container">
      <label aria-label class="form-label">Email Address *</label>
      <mat-form-field
        [floatLabel]="'always'"
        class="form-field"
        appearance="outline"
        [color]="'accent'"
      >
        <input
          formControlName="email"
          class="form-input"
          matInput
          placeholder="e.g. janedoe@email.com"
        />
      </mat-form-field>
      <mat-error
        *ngIf="
          businessContactForm.get('email')?.hasError('required') &&
          myform.submitted
        "
        >Email Is A Required Field</mat-error
      >
      <mat-hint
        class="hint"
        *ngIf="
          businessContactForm.get('email')?.dirty
            ? businessContactForm.get('email')?.invalid
            : null
        "
        >Invalid Email Format</mat-hint
      >
    </div>
    <div class="form-input-container">
      <label aria-label class="form-label">Company Name *</label>
      <mat-form-field
        [floatLabel]="'always'"
        class="form-field"
        appearance="outline"
        [color]="'accent'"
      >
        <input
          formControlName="companyName"
          class="form-input"
          matInput
          placeholder="e.g. Jane Doe Fashion Emporium"
        />
      </mat-form-field>
      <mat-error
        *ngIf="
          businessContactForm.get('companyName')?.hasError('required') &&
          myform.submitted
        "
        >Company Name Is A Required Field</mat-error
      >
    </div>
  </div>
  <div class="form-row">
    <div class="form-input-container-single">
      <label aria-label class="form-label">Your Message *</label>
      <mat-form-field
        [floatLabel]="'always'"
        class="form-message"
        appearance="outline"
        [color]="'accent'"
      >
        <textarea
          class="form-input"
          matInput
          formControlName="message"
          placeholder="Details about the business and how PAXI can be of assistance"
          rows="4"
          [cols]="20"
        ></textarea>
      </mat-form-field>
      <mat-error
        *ngIf="
          businessContactForm.get('message')?.hasError('required') &&
          myform.submitted
        "
        >Message Is A Required Field</mat-error
      >
    </div>
  </div>

  <ng-container *ngIf="!loading; else loadingTemplate">
    <div class="form-row">
      <button type="submit" class="form-button" mat-flat-button>Contact</button>
    </div>
  </ng-container>
  <ng-template #loadingTemplate>
    <div class="form-row">
      <mat-spinner [diameter]="50" [color]="spinnerColour"></mat-spinner>
    </div>
  </ng-template>
</form>
