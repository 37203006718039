import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BusinessContactComponent } from './containers/business-contact/business-contact.component';

const routes: Routes = [
  {
    path: 'business-contact',
    component: BusinessContactComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class ContactFormsRoutingModule {}
